
import { useStore } from "vuex";
import { provide, ref } from "vue";
import store from "@/store";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";
import UnlockForm from "@/components/UnlockForm.vue";
import {
  useRouter,
  useRoute,
  RouteLocationNormalized,
  NavigationGuardNext,
} from "vue-router";
import { UserActionTypes } from "@/store/modules/user/actions";

export default {
  name: "Unlock",
  components: { UnlockForm, DefaultLoader },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isNowLoading = ref(false);

    provide("loginLoading", (value: boolean) => {
      isNowLoading.value = value;
    });

    const logoutHandler = async function () {
      await store.dispatch(UserActionTypes.Logout);
      await router.replace((route.query.redirect as string) ?? "/");
    };

    return {
      store,
      logoutHandler,
      isNowLoading,
    };
  },
  beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    if (!store.getters.userIsLocked) {
      next((to.query.redirect as string) ?? "/");
      return;
    }

    next();
  },
};
