<template>
  <div class="position-relative">
    <div v-if="loading" class="loader-container" @click.stop>
      <div class="spinner-border mx-auto my-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "DefaultLoader",
  props: ["loading"]
};
</script>

<style scoped>
.loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(99, 99, 99, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
