import request from "../utils/request";

export function apiGet(url: string, params = {}) {
  return request({
    url: url,
    method: "get",
    params: params,
  });
}

export function apiPost(url: string, data = {}, params = {}) {
  return request({
    url: url,
    method: "post",
    params: params,
    data,
  });
}

export function apiDelete(url: string, params = {}) {
  return request({
    url: url,
    method: "delete",
    params: params,
  });
}

export function apiPatch(url: string, data = {}, params = {}) {
  return request({
    url: url,
    method: "patch",
    params: params,
    data,
  });
}

export function apiPut(url: string, data = {}, params = {}) {
  return request({
    url: url,
    method: "put",
    params: params,
    data,
  });
}
