<template>
  <DefaultLoader :loading="isNowLoading">
    <div
      class="signin-inner my-3 my-lg-0 bg-white shadow-soft border border-light rounded p-4 p-lg-5 w-100 fmxw-500"
    >
      <div class="text-center text-md-center mb-4 mt-md-0">
        <div class="user-avatar large-avatar mx-auto mb-3 border-dark p-2">
          <img
            class="rounded-circle"
            alt="Image placeholder"
            src="@/assets/logo.png"
          />
        </div>
        <h1 class="mb-3 h3">{{ store.getters.email }}</h1>
        <p class="text-gray">Please input your password to continue.</p>
      </div>
      <UnlockForm />
      <div class="d-flex justify-content-center align-items-center mt-4">
        <span class="font-weight-normal">
          Not you?
          <a
            href="javascript:void(0)"
            class="font-weight-bold"
            @click="logoutHandler"
          >
            Logout
          </a>
        </span>
      </div>
    </div>
  </DefaultLoader>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { provide, ref } from "vue";
import store from "@/store";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";
import UnlockForm from "@/components/UnlockForm.vue";
import {
  useRouter,
  useRoute,
  RouteLocationNormalized,
  NavigationGuardNext,
} from "vue-router";
import { UserActionTypes } from "@/store/modules/user/actions";

export default {
  name: "Unlock",
  components: { UnlockForm, DefaultLoader },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isNowLoading = ref(false);

    provide("loginLoading", (value: boolean) => {
      isNowLoading.value = value;
    });

    const logoutHandler = async function () {
      await store.dispatch(UserActionTypes.Logout);
      await router.replace((route.query.redirect as string) ?? "/");
    };

    return {
      store,
      logoutHandler,
      isNowLoading,
    };
  },
  beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    if (!store.getters.userIsLocked) {
      next((to.query.redirect as string) ?? "/");
      return;
    }

    next();
  },
};
</script>

<style scoped></style>
