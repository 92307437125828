<template>
  <form action="#" class="mt-4" @submit.prevent="handleLogin">
    <template v-if="requireOtp">
      <div class="form-group mb-4">
        <label for="otp">One time OTP</label>
        <InputWrapper :errors="formError.otp">
          <span class="input-group-text"
            ><span class="fas fa-user-shield"></span
          ></span>
          <input
            v-model="formData.otp"
            type="number"
            class="form-control px-1"
            placeholder="******"
            id="otp"
            required
          />
        </InputWrapper>
      </div>
    </template>
    <div v-else class="mb-4">
      <label v-show="false" for="password">Your Password</label>
      <InputWrapper :errors="formError.password">
        <span class="input-group-text"
          ><span class="fas fa-unlock-alt"></span
        ></span>
        <input
          :type="passwordShow ? 'text' : 'password'"
          required
          placeholder="Password"
          class="form-control px-1"
          id="password"
          v-model="formData.password"
        />
        <span
          class="input-group-text pointer"
          @click="passwordShow = !passwordShow"
          ><span
            :class="passwordShow ? 'text-danger' : ''"
            class="far fa-eye"
          ></span
        ></span>
      </InputWrapper>
    </div>
    <div class="mt-3">
      <button type="submit" class="btn w-100 btn-dark">Unlock</button>
    </div>
  </form>
</template>

<script lang="ts">
import { ref, inject, defineComponent } from "vue";
import { apiPost } from "@/api";
import { useStore } from "vuex";
import InputWrapper from "@/components/InputWrapper.vue";
import { Toast } from "@/utils/notify";
import { useRoute, useRouter } from "vue-router";
import { UserActionTypes } from "@/store/modules/user/actions";

export default defineComponent({
  name: "UnlockForm",
  components: { InputWrapper },
  setup: function () {
    const store = useStore();
    const formError = ref({});
    const passwordShow = ref(false);
    const route = useRoute();
    const router = useRouter();
    const formData = ref({
      email: store.getters.email,
      password: null,
      otp: undefined,
    });
    const requireOtp = ref(false);

    const rememberMe = ref(false);
    const loadingContext = inject("loginLoading") as (val: boolean) => void;

    async function handleLogin() {
      loadingContext(true);
      try {
        const response = await apiPost(
          "admin/user-account/sign-in",
          formData.value
        );
        if (response.status === 201) {
          requireOtp.value = true;
        } else {
          await store.dispatch(UserActionTypes.Login, response.data.data);
          await router.push((route.query.redirect as string) ?? "/");
        }
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: e.message,
        }).then();
      }
      loadingContext(false);
    }

    return {
      formData,
      formError,
      rememberMe,
      passwordShow,
      requireOtp,
      handleLogin,
    };
  },
});
</script>

<style scoped></style>
