<template>
  <div class="input-group" :class="errors ? 'is-invalid' : ''">
    <slot></slot>
  </div>
  <template v-if="errors && errors.length">
    <div v-for="(error, index) in errors" :key="index" class="invalid-feedback">
      {{ error }}
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "InputWrapper",
  props: ["errors"],
});
</script>

<style scoped></style>
